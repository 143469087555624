import GATSBY_COMPILED_MDX from "/codebuild/output/src3146426229/src/content/pages/5--terms/index.md";
import React from "react";
import PropTypes from "prop-types";
import {graphql} from "gatsby";
import Seo from "../components/Seo";
import Article from "../components/Article";
import Page from "../components/Page";
import {MDXProvider} from "@mdx-js/react";
import {ThemeContext} from "../layouts";
;
const PageTemplate = ({data, children}) => {
  return React.createElement(React.Fragment, null, React.createElement(ThemeContext.Consumer, null, theme => React.createElement(Article, {
    theme: theme
  }, React.createElement(Page, {
    title: data.page.frontmatter.title,
    theme: theme
  }, React.createElement(MDXProvider, null, children)))), React.createElement(Seo, {
    data: data.page,
    facebook: data.site.siteMetadata.facebook
  }));
};
PageTemplate
export default function GatsbyMDXWrapper(props) {
  return React.createElement(PageTemplate, props, React.createElement(GATSBY_COMPILED_MDX, props));
}
export const pageQuery = graphql`
  query ($id: String!) {
    page: mdx (id: { eq: $id }) {
      id
      fields {
        slug
      }
      frontmatter {
        title
      }
    }
    site {
      siteMetadata {
        facebook {
          appId
        }
      }
    }
  }
`;
